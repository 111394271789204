import request from '@/utils/request'
import constant from '@/constant'
//防疫通知messageNotice
function pageList(formData) {
  return request.post(constant.serverUrl + "/messageNotice/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/messageNotice/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/messageNotice/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/messageNotice/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/messageNotice/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/messageNotice/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/messageNotice/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function list() {
  return request.post(constant.serverUrl + "/messageNotice/list");
}

function sendWechat(formData) {
  return request.post(constant.serverUrl + "/messageNotice/sendWechat", formData);
}

export default {
  create, edit, add, update, remove, batchRemove, pageList, list, sendWechat
}