<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/messageNotice">通知公告</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="标题" prop="title">
        <el-input type="text" size="mini" v-model="queryModel.title"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="title">
        <el-select
          v-model="queryModel.type"
          placeholder="请选择"
          style="width: 90%"
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="站内消息" value="1"></el-option>
          <el-option label="微信消息" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      stripe
      :height="tableHeight"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="type" label="发送类型" width="100">
        <template slot-scope="{ row }">
          <span v-if="row.type == 1" style="color: green">站内消息</span>
          <span v-else-if="row.type == 2" style="color: red">微信</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题" width="300"></el-table-column>
      <el-table-column
        prop="noticeCompany"
        label="接收单位"
        width="300"
      ></el-table-column>
      <el-table-column prop="point" label="积分" width="80"></el-table-column>
      <el-table-column prop="status" label="审核" width="120">
        <template slot-scope="{ row }">{{
          row.status ? "已审核" : "未审核"
        }}</template>
      </el-table-column>
      <el-table-column prop="sendWechat" label="发送状态" width="120">
        <template slot-scope="{ row }">
          <span v-if="row.type == 2">
            {{ row.sendWechat ? "已发送微信消息" : "未发送" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="发送时间"
        width="280"
      ></el-table-column>
      <el-table-column label="操作" width="480">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            @click="sendWechat(row)"
            v-if="row.type == '2' && row.sendWechat == 0"
            >发送消息</el-button
          >
          <el-button size="mini" type="warning" @click="handleEdit(row)"
            >修改</el-button
          >
          <el-button size="mini" type="danger" @click="handleDelete(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <messageNotice-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></messageNotice-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import messageNoticeDetail from "./messageNotice-detail";
import messageNoticeApi from "@/api/base/messageNotice";
import pageUtil from "@/utils/page";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "BaseMessageNoticeList",
  data() {
    var self = this;

    return {
      queryModel: {
        title: "",
        type: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      tableHeight: 0,
    };
  },
  components: {
    "messageNotice-detail": messageNoticeDetail,
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("title", self.queryModel.title);
      formData.append("type", self.queryModel.type);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      messageNoticeApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;

          //45为分页栏的高度
          //页面高度-列表上面的高度-分页栏高度
          self.tableHeight = pageUtil.autoAdjustHeight(
            self.$refs.formTable.$el
          );
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          messageNoticeApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        messageNoticeApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    sendWechat(record) {
      var self = this;
      var formData = new FormData();

      formData.append("id", record.id);
      this.$confirm("确认发送微信通知？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        self.loading = true;
        messageNoticeApi.sendWechat(formData).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "发送成功!",
            });
          }
        });
      });
    },
  },
  mounted: function () {
    var self = this;
    this.changePage(1);
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>