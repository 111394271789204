
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    :width="'800px'"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-form-item label="类型" prop="title">
          <el-select
            v-model="formModel.type"
            placeholder="请选择"
            height="200"
          >
            <el-option label="站内消息" value="1"></el-option>
            <el-option label="微信消息" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="formModel.title"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="发送单位" prop="noticeCompany">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.noticeCompany"
            height="200"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="接受者类型" prop="noticeType">
          <el-select
            v-model="formModel.noticeType"
            multiple
            placeholder="请选择"
            style="width: 90%"
          >
            <el-option
              v-for="result in popedomData"
              :key="result.id"
              :label="result.name"
              :value="result.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="微信模板ID" prop="wechatCode" v-if="formModel.type == '2'">
          <el-input
            v-model="formModel.wechatCode"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="链接地址" prop="noticeLink" v-if="formModel.type == '2'">
          <el-input
            v-model="formModel.noticeLink"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <mce-editor
            ref="editor"
            uploadName="upfile"
            v-model="formModel.content"
            :url="uploadUrl"
            :config="editorConfig"
          ></mce-editor>
        </el-form-item>
        <el-form-item label="需要签字" prop="point">
          <el-radio-group v-model="formModel.isSign">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="领取积分" prop="point">
          <el-radio-group v-model="formModel.isReceive">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="积分" prop="point">
          <el-input
            v-model="formModel.point"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核状态" prop="status">
          <el-radio-group v-model="formModel.status">
            <el-radio :label="true">已审核</el-radio>
            <el-radio :label="false">未审核</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="微信消息推送" prop="sendWechat" v-if="formModel.type == '2'">
          <el-radio-group v-model="formModel.sendWechat">
            <el-radio :label="true">已发送</el-radio>
            <el-radio :label="false">未发送</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import messageNoticeApi from "@/api/base/messageNotice";
import companyInfoApi from "@/api/base/companyInfo";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import SelectTree from "@/components/SelectTree";
import { getToken } from "@/utils/auth";
import MceEditor from "@/components/Tinymce";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        type:[{ required: true, message: "请选择消息类型", trigger: "blur" }],
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        noticeCompany: [
          { required: true, message: "发送单位不能为空", trigger: "blur" },
        ],
        wechatCode:[{ required: true, message: "模板ID不能为空", trigger: "blur" }],
        noticeLink:[{ required: true, message: "链接不能为空", trigger: "blur" }],
        noticeType: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      companyResult: [],
      editorConfig: {
        height: 300,
        width: 660,
      },
      uploadUrl: Constant.serverUrl + "/tinymce/upload?token=" + getToken(),
      popedomData: [],
      classifyData: [],
      typeData: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    this.loadTree();
  },
  methods: {
    loadTree() {
      var formData = new FormData();
      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          this.treeData = jsonData.data;
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    loadChildren(tree, treeNode, resolve) {
      var formData = new FormData();
      formData.append("parentId", tree.id);

      companyInfoApi.loadChildren(formData).then((resp) => {
        var jsonData = resp.data;

        if (jsonData.result) {
          resolve(jsonData.data);
        } else {
          this.$message.error(jsonData.message + "");
        }
      });
    },
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            self.formModel.content = self.$refs.editor.getContent();

            // //子单位
            // var noticeCompany = self.formModel.noticeCompany.join(",");
            // self.formModel.noticeCompany = noticeCompany;

            var noticeType = self.formModel.noticeType.join(",");
            self.formModel.noticeType = noticeType;

            if (id == null || id.length == 0) {
              return messageNoticeApi.add(self.formModel);
            } else {
              return messageNoticeApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    console.log("detail mounted");

    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    var formData = new FormData();
    formData.append("catalogName", "用户身份");
    dataDictionaryApi.findByCatalogName(formData).then((response) => {
      var jsonData = response.data;
      this.popedomData = jsonData.data;
    });

    var formData2 = new FormData();
    formData2.append("catalogName", "通知分类");
    dataDictionaryApi.findByCatalogName(formData2).then((response) => {
      var jsonData = response.data;
      this.classifyData = jsonData.data;
    });

    (function () {
      if (self.businessKey.length == 0) {
        return messageNoticeApi.create();
      } else {
        return messageNoticeApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;

          if (self.formModel.content != null) {
            self.$refs.editor.setContent(self.formModel.content);
          }

          if (self.businessKey.length == 0) {
            self.formModel.status = false;
          }

          // var noticeCompany = self.formModel.noticeCompany;
          // if (noticeCompany != null) {
          //   self.formModel.noticeCompany = noticeCompany.split(",");
          // }
          var noticeType = self.formModel.noticeType;
          if (noticeType != null) {
            self.formModel.noticeType = noticeType.split(",");
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "mce-editor": MceEditor,
    "el-select-tree": SelectTree,
  },
};
</script>
<style>
.ql-editor {
  height: 300px;
}
</style>